<template>
  <div>
    <v-btn
      :to="{ name: 'destructionPackagingMaterialCreateUpdate' }"
      class="btn btn-success btn-elevate kt-login__btn-primary text-white"
    >
      {{ $t("BUTTON.ADD_DESTRUCTION_PROTOCOL") }}</v-btn
    >
    <v-container>
      <v-row no-gutters>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <!-- <v-checkbox v-model="tableOptions.showSearch" 
                      label="Търси по колона" 
                      class="shrink"></v-checkbox>  -->
        </v-col>

        <v-col class="text-right" cols="2">
          <v-select
            v-model="headers.rows"
            :items="tableOptions.rowsPerPage"
            :label="$t('A.PER_PAGE')"
            chips
            attach
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <template v-for="column of headers.columns">
          <v-col
            :key="column.name"
            :cols="column.cols"
            v-if="column.hide != true"
          >
            <span @click="changeOrder(column.name)">
              {{ $t("LABELS." + column.name) }}
              <span v-if="column.name == headers.order">{{
                orderIcon
              }}</span></span
            >
          </v-col>
        </template>
        <v-col cols="1">
          Операции
        </v-col>
      </v-row>

      <div v-show="!isLoadingDestructionPackagingMaterials">
        <v-row class="mb-6 t-row" v-for="item of items" :key="item.id">
          <template v-for="col of headers.columns">
            <v-col
              class="t-col"
              :key="col.name"
              :cols="col.cols"
              v-if="col.hide != true"
            >
              <template v-if="col.format">
                {{ formatDate(item[col.name], col.format) }}
              </template>
              <template v-else>
                {{ item[col.name] }}
              </template>
            </v-col>
          </template>

          <v-col
            cols="1"
            class="t-col d-flex justify-content-start operation-container flex-wrap"
          >
            <DownloadPdfButton
              :id="item.id"
              file-type="destruction-protocol"
              text="Изтегли протокол"
              list-action="destructionPackagingMaterialList"
            />
            <PrintStickersButton :items="item.stickersItems" :onlyRed="true" />
            <LogLink
              :id="item.id"
              type="destruction-packaging-material"
              class-name="row"
            />
            <!-- <a href="#" class="float-right" @click="deleteData(item)">Изтрий</a> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-right">
            {{ totalItems }} {{ $t("A.RESULTS") }}
          </v-col>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingDestructionPackagingMaterials"
      ></v-progress-linear>

      <Paginator
        :totalPages="totalPages"
        :page="headers.page"
        :maxVisibleButtons="tableOptions.maxVisibleButtons"
        @change-page="changePage"
      />
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  GET_DESTRUCTION_PACKAGING_MATERIALS,
  SIGN_DESTRUCTION_PACKAGING_MATERIAL,
  DELETE_DESTRUCTION_PACKAGING_MATERIAL
} from "@/store/destructionPackagingMaterials.module";

import { mapGetters } from "vuex";

import Paginator from "@/components/Paginator";

import ListMixin from "@/common/mixins/List";
import LogLink from "@/components/Log/LogLink";
import DownloadPdfButton from "@/components/ActionButtons/DownloadPdfButton";
import PrintStickersButton from "@/components/ActionButtons/PrintStickersButton";
import moment from "moment";

export default {
  name: "ListDestructionPackagingMaterials",

  components: { LogLink, DownloadPdfButton, Paginator, PrintStickersButton },
  mixins: [ListMixin],
  data() {
    return {
      action: "DestructionPackagingMaterials",
      items: [],
      totalItems: 0,
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "client.name",
            search: ""
          },
          {
            name: "signed_by_qualified",
            search: "",
            hide: true
          },
          {
            name: "materialName",
            search: ""
          },
          {
            name: "createdAt",
            format: "DD.MM.YYYY HH:mm"
          },
          {
            name: "stickers_items",
            search: "",
            hide: true
          }
        ],
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingDestructionPackagingMaterials"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    }
  },
  watch: {
    "headers.search": _.debounce(function(new_value) {
      let vm = this;

      vm.headers.page = 1;
      if (new_value == null || new_value.length >= 3) {
        vm.fetchData();
      }
    }, 250),
    "headers.rows": _.debounce(function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function() {
      let vm = this;
      vm.fetchData();
    }, 250)
  },
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Сертификат за освобождаване на опаковъчни материали" }
    ]);
    vm.fetchData();
  },
  methods: {
    formatDate: function(rr, format) {
      return moment(rr).format(format);
    },
    sign: function(id) {
      let vm = this;
      var action = SIGN_DESTRUCTION_PACKAGING_MATERIAL;

      this.error_messages = [];

      vm.$store
        .dispatch(action, id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "destructionPackagingMaterialList" });
          }
        });
    },
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    fetchData() {
      let vm = this;

      vm.getOptions();

      vm.$store
        .dispatch(GET_DESTRUCTION_PACKAGING_MATERIALS, { payload: vm.headers })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.data;
            vm.totalItems = data.data.recordsTotal;
            vm.totalPages = data.data.totalPages;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "destructionPackagingMaterialList" });
          }
        });
    },
    deleteData: _.debounce(function(item) {
      let vm = this;

      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "Tова дейтсвие не може да бъде отменено!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.execDelete(item);
        },
        onCancel() {}
      });
    }, 500),
    execDelete: _.debounce(function(item) {
      let vm = this;

      vm.$store
        .dispatch(DELETE_DESTRUCTION_PACKAGING_MATERIAL, item.id)
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.fetchData();
          // vm.$nextTick(function() {
          //   vm.items = data.data.data;
          //   vm.totalItems = data.data.recordsTotal;
          //   vm.totalPages = data.data.totalPages;
          // });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "destructionPackagingMaterialList" });
          }
        });
    }, 500)
  },
  beforeDestroy: function() {
    this.items = null;
    delete this.items;
  }
};
</script>
