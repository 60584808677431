<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-2 mt-2"
          fab
          dark
          x-small
          color="#3dbdad"
          v-bind="attrs"
          v-on="on"
          @click="stickersModal = true"
        >
          <v-icon>
            fas fa-print
          </v-icon>
        </v-btn>
      </template>
      <span>Принтирай стикери</span>
    </v-tooltip>
    <v-dialog v-model="stickersModal" width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Принтиране на стикери
        </v-card-title>
        <v-card-text style="font-size: 15px; padding-bottom: 0;">
          <hr />
          <span style="color: red">
            Моля убедете се, че принтера работи и е включен във Вашия компютър!
          </span>
          <hr />
          <span style="color: black">
            Моля заредете принтера
            {{ this.stickerColor === "green" ? "със" : "с" }}
            <span :style="`color: ${stickerColor}`">
              {{ stickerColorName }}
            </span>
            стикери!
          </span>
          <hr />
        </v-card-text>
        <v-card-text>
          <div v-if="printerError" class="alert alert-danger">
            Няма връзка с принтера
          </div>
          <v-col v-if="items.length > 1" cols="12">
            <div class="form-group">
              <label for="">
                <span> Сертификат:</span>
              </label>
              <div class="field-wrap">
                <vu-select
                  :options="itemOptions"
                  label="label"
                  v-model="currentItem"
                ></vu-select>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="form-group">
              <label for="">
                <span> Брой:</span>
              </label>
              <div class="field-wrap">
                <input
                  type="number"
                  class="form-control"
                  min="1"
                  value="1"
                  step="1"
                  v-model="number"
                />
              </div>
            </div>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <form v-if="currentItem" method="post" action="http://127.0.0.1:1914">
            <input type="hidden" name="quantity" :value="parseInt(number)" />
            <input type="hidden" name="status" :value="status" />
            <input type="hidden" name="bom" :value="currentItem.bom" />
            <input type="hidden" name="nameLp" :value="currentItem.nameLp" />
            <input type="hidden" name="batch" :value="currentItem.batch" />
            <input type="hidden" name="name" :value="currentItem.name" />
            <input
              type="hidden"
              name="date"
              :value="currentItem.date | formatDate"
            />
            <input type="hidden" name="redirectUrl" :value="redirectUrl" />
            <v-btn text class="btn-success text-white" type="submit">
              Принтирай
            </v-btn>
          </form>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    items: {
      type: Array,
      required: true
    },

    onlyRed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentItem: null,
      status: "",
      stickerColor: "",
      stickerColorName: "",
      redirectUrl: window.location.href,
      number: 1,
      stickersModal: false,
      printerError: false
    };
  },

  mounted() {
    this.initStickersItem();
  },

  watch: {
    stickersModal() {
      this.initStickersItem();
    }
  },

  computed: {
    itemOptions() {
      return _.map(this.items, item => ({
        ...item,
        label: `${item.bom} ${item.nameLp} (${item.batch})`
      }));
    }
  },

  methods: {
    initStickersItem() {
      this.currentItem = this.itemOptions[0];

      this.getStickerStatus(this.currentItem.statusId);
    },

    getStickerStatus(statusId) {
      if (this.onlyRed) {
        statusId = 3;
      }

      switch (statusId) {
        case 2:
          this.status = "ОСВОБОДЕНО";
          this.stickerColor = "green";
          this.stickerColorName = "ЗЕЛЕНИ";
          break;

        case 3:
          this.status = "БЛОКИРАН";
          this.stickerColor = "red";
          this.stickerColorName = "ЧЕРВЕНИ";
          break;

        default:
          this.status = "ПРОБОВЗЕТО/КАРАНТИНА";
          this.stickerColor = "#e3e323";
          this.stickerColorName = "ЖЪЛТИ";
          break;
      }
    }
  }
};
</script>

<style scoped></style>
