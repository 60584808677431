<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2 mt-2"
        fab
        dark
        x-small
        color="#3dbdad"
        v-bind="attrs"
        v-on="on"
        @click="downloadPDF(fileType, id)"
      >
        <v-icon>
          fas fa-file-alt
        </v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
import _ from "lodash";
import { GET_FILES_BY_TYPE, GET_FILE } from "@/store/files.module";
import { APP_URL } from "@/common/config";

export default {
  name: "DownloadPdfButton",
  props: ["id", "fileType", "text", "listAction"],
  methods: {
    downloadPDF: _.debounce(function(filetype, id) {
      let vm = this;

      vm.$store
        .dispatch(GET_FILES_BY_TYPE, {
          file_type: filetype,
          resource_id: id
        })
        .then(data => {
          vm.getFile(data.data[0].id);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });

            vm.$router.push({ name: vm.listAction });
          }
        });
    }, 500),
    getFile: _.debounce(function(id) {
      let vm = this;

      vm.$store
        .dispatch(GET_FILE, id)
        .then(data => {
          vm.streamFile(data.data.code);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });

            vm.$router.push({ name: vm.listAction });
          }
        });
    }, 500),
    streamFile: function(code) {
      window.open(APP_URL + "file/" + code);
    }
  }
};
</script>

<style scoped></style>
