export default {
  data() {
    return {
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      totalPages: 0
    };
  },
  methods: {
    changePage: function(value) {
      this.headers.page = value;
    }
  }
};
