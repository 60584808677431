<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2 mt-2"
        fab
        dark
        x-small
        color="#3dbdad"
        v-bind="attrs"
        v-on="on"
        v-if="userCanViewLogs"
        @click="openLog"
      >
        <v-icon>
          fas fa-clipboard-list
        </v-icon>
      </v-btn>
    </template>
    <span>Лог</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["type", "id", "className"],
  name: "LogLink",
  computed: {
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    },
    userCanViewLogs: function() {
      return ["Admin", "Qualified", "Manager", "Hall"].includes(this.user.role);
    }
  },
  methods: {
    openLog: function() {
      const vm = this;
      window.open(
        `/logs/${vm.type}/${vm.id}`,
        "_blank",
        "width=600,height=800"
      );
    }
  }
};
</script>

<style scoped></style>
