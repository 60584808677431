<template>
  <div>
    <v-row v-if="totalPages > 0">
      <v-col class="text-center paginator">
        <v-btn
          class="m-1"
          v-for="page in visiblePages"
          :key="page.name"
          :disabled="page.isDisabled"
          depressed
          small
          @click="$emit('change-page', page.name)"
        >
          {{ page.name }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Paginator",

  props: {
    totalPages: {
      type: Number,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    maxVisibleButtons: {
      type: Number,
      required: true
    }
  },
  computed: {
    visiblePages() {
      const range = [];

      let start, end;

      if (this.page < Math.ceil(this.maxVisibleButtons / 2)) {
        start = 1;

        if (this.maxVisibleButtons > this.totalPages) {
          end = this.totalPages;
        } else {
          end = this.maxVisibleButtons;
        }
      } else if (
        this.page + Math.ceil(this.maxVisibleButtons / 2) >
        this.totalPages
      ) {
        if (this.totalPages < this.maxVisibleButtons) {
          start = 1;
        } else {
          start = this.totalPages - this.maxVisibleButtons + 1;
        }

        end = this.totalPages;
      } else {
        start = this.page - Math.floor(this.maxVisibleButtons / 2);
        end = this.page + Math.floor(this.maxVisibleButtons / 2);
      }

      for (let i = start; i <= end; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.page
        });
      }

      return range;
    }
  }
};
</script>
